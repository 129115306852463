<template>
  <div style="margin-top:20px">
    <div class="search_flex">
      <div class="search_left">
         <selectComp
          :hval="orderStatus"
          :data="orderStatusList"
          @change="orderStatusChange"
          placeholder="工单状态"
        />
        <selectComp
          :hval="qualityStatus"
          :data="statusList"
          @change="statusChange"
          placeholder="品控状态"
        />
        <inputComp :hval="code" @blur="codeBlur" placeholder="工单编号" />
         <selectComp
          :hval="storeSettlementType"
          :data="storeSettlementTypeList"
          @change="storeSettlementTypeChange"
          placeholder="工单管理"
        />
        <inputComp :hval="storeName" @blur="storeNameBlur" placeholder="商家名" />
        <inputComp
          :hval="orderCode"
          @blur="orderCodeBlur"
          placeholder="订单编号"
        />
        <inputComp :hval="serialNum" @blur="serialNumBlur" placeholder="设备序列号" />
        <goodCategory
          :hval="categoryId"
          @change="categoryIdChange"
        />
         <selectComp
          :hval="configFaultId"
          :data="deviceFaultList"
          :optionsParams="{value:'id',label:'appearance'}"
          @change="configFaultChange"
          placeholder="故障现象(先选商品类目)"
        />
        <selectComp
          :hval="failCat"
          :data="failCatList"
          :optionsParams="{value:'code',label:'name'}"
          @change="failCatChange"
          placeholder="问题归类"
        />
        <selectComp
          :hval="ownershipResponsibility"
          :data="ownershipResponsibilityList"
          :optionsParams="{value:'code',label:'name'}"
          @change="ownershipResponsibilityChange"
          placeholder="责任归属"
        />
        <selectComp
          :hval="causeAnalysis"
          :data="causeAnalysisList"
          :optionsParams="{value:'code',label:'name'}"
          @change="causeAnalysisChange"
          placeholder="开箱不良原因"
        />
        <selectComp
          :hval="repairNum"
          :data="repairNumList"
          @change="repairNumChange"
          placeholder="报修次数"
        />
        <!-- 业务类型、工单分类 -->
        <selectComp
          :data="orderTypeList"
          :hval="orderType"
          @change="orderTypeChange"
          placeholder="业务类型"
        />
        <selectComp
          :data="orderTypeChiList"
          :hval="orderTypeChi"
          @change="orderTypeChiChange"
          placeholder="工单类型(要先选业务类型)"
        />
        <dateComp
          :hval="date"
          startPlaceholder="报修开始日期"
          endPlaceholder="报修结束日期"
          @change="dateChange"
        />
        <btnGroup @search="search" @reset="reset" />
        <btnComp
        v-show="tabType==='bad'"
          :disabled="allotDisabled"
          btnType="plain"
          @click="batchHandleRepeatCheck"
          v-permission="'批量复查'"
          >批量复查</btnComp
        >
        <btnComp
          v-show="tabType==='bad'"
          :disabled="allotDisabled"
          btnType="plain"
          @click="batchHandle"
          v-permission="'批量取消开箱不良'"
          >批量取消开箱不良</btnComp
        >
        <btnComp
          v-show="tabType==='good'"
          :disabled="allotDisabled"
          btnType="plain"
          @click="batchHandle"
          v-permission="'批量改为开箱不良'"
          >批量改为开箱不良</btnComp
        >
      </div>
    </div>
    <tableComp
      v-loading="loading"
      :data="tableData"
      :col-configs="theadName"
      :total="total"
      :currentPage="curPage"
      :pageSize="pageSize"
      @select="select"
      isShowCheckBox
      @selectAll="selectAll"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column slot="orderStatusName" label="工单状态" width="170">
        <template v-slot="scope">
          <div>{{scope.row.orderStatusName}}</div>
          <div v-if="scope.row.orderTypeName">[{{scope.row.orderTypeName}}/{{scope.row.orderTypeChiName}}]</div>
        </template>
      </el-table-column>
      <el-table-column slot="code" label="报修工单号(报修时间)">
        <template v-slot="scope">
          <span>
            {{ `${scope.row.code}(${scope.row.orderCreatedTime })` }}
          </span>
        </template>
      </el-table-column>
       <el-table-column slot="code" label="商家/订单号">
        <template v-slot="scope">
          <span>
            {{ `${scope.row.storeName} / ${scope.row.orderCode }` }}
          </span>
        </template>
      </el-table-column>
      <el-table-column slot="operate" label="操作" fixed="right" width="130">
        <template v-slot="scope">
          <btnComp
            btnType="text"
            @click="audit(scope.row)"
            v-permission="'进入复核'"
            >进入复核</btnComp
          >
        </template>
      </el-table-column>
    </tableComp>
    <!-- 详情弹窗 -->
    <drawerDetail @close="close" />
    <!-- 批量操作 弹窗 -->
    <batchHandle
      :isShow="batchHandleShow"
      @close="close"
      :selectIds="selectIds"
      :tabType="tabType"
    />
     <dialogComp title="提示" :isShow="hintShow" @close="close" @sure="hintSure">
      <p>确定批量复查吗？</p>
    </dialogComp>
  </div>
</template>

<script>
import drawerDetail from '@/views/work-order/all-order/detail'
import goodCategory from '@/views/components/goodCategory'
import batchHandle from './batchHandle'
import { dictThree, deviceFaulThree, qcCheckList, qcCheckBatchCheck } from '@/api'
import {
  orderTypeList,
  orderTypeChiList1,
  orderTypeChiList2,
  orderTypeChiList3,
  orderStatusListToQC,
  statusListToQC,
  storeSettlementTypeList,
  repairNumList
} from '@/utils/constData'
import { mapMutations } from 'vuex'
export default {
  components: {
    drawerDetail,
    goodCategory,
    batchHandle
  },
  props: {
    // tabs类型
    tabType: {
      type: String,
      default: 'bad'
    }
  },
  data () {
    return {
      loading: false,
      batchHandleShow: false,
      hintShow: false,
      selectIds: [],
      hintContent: '', // 提示框动态内容
      curPage: 1,
      pageSize: 10,
      total: 0,
      qualityStatus: 0,
      orderStatus: '',
      code: '',
      serialNum: '',
      orderCode: '',
      storeName: '',
      categoryId: '',
      storeSettlementType: '',
      deviceFaultList: [],
      causeAnalysisList: [],
      failCatList: [],
      ownershipResponsibilityList: [],
      configFaultId: '',
      failCat: '',
      ownershipResponsibility: '',
      causeAnalysis: '',
      date: [],
      startTime: '',
      endTime: '',
      orderType: '',
      orderTypeChi: '',
      orderTypeChiList: [],
      repairNum: '',
      tableData: [],
      theadName: [
        { slot: 'orderStatusName' },
        { prop: 'qualityStatusName', label: '品控状态' },
        { slot: 'code' },
        { slot: 'storeName' },
        { prop: 'categoryName', label: '设备分类' },
        { prop: 'serialNum', label: '序列号' },
        { prop: 'configFaultContent', label: '故障现象' },
        { prop: 'failCatContent', label: '问题归类' },
        { prop: 'ownershipResponsibilityContent', label: '责任归属' },
        { prop: 'causeAnalysisContent', label: '开箱不良原因' },
        { slot: 'operate' }
      ]
    }
  },
  computed: {
    allotDisabled () {
      return !(this.selectIds.length > 0)
    },
    // 定义的常量转换
    orderTypeList () {
      return orderTypeList
    },
    orderTypeChiList1 () {
      return orderTypeChiList1
    },
    orderTypeChiList2 () {
      return orderTypeChiList2
    },
    orderTypeChiList3 () {
      return orderTypeChiList3
    },
    orderStatusList () {
      return orderStatusListToQC
    },
    storeSettlementTypeList () {
      return storeSettlementTypeList
    },
    repairNumList () {
      return repairNumList
    },
    statusList () {
      return statusListToQC
    }
  },
  mounted () {
    this.getSelectData(101015, 'failCatList')
    this.getSelectData(101018, 'causeAnalysisList')
    this.getSelectData(101024, 'ownershipResponsibilityList')
    this.getList()
  },
  methods: {
    ...mapMutations(['SET_DRAWER_SHOW', 'SET_INDEX_INFO']),
    // 获取展开后  多个下拉列表 问题归类\原因分析\处理方式
    async getSelectData (code, data) {
      const params = {
        code
      }
      const res = await dictThree(params)
      this[data] = res.data
    },
    // 获取故障现象下拉
    getDeviceFaultList (categoryId) {
      deviceFaulThree({ categoryId }).then((res) => {
        this.deviceFaultList = res.data
      })
    },
    getList () {
      const params = {
        poorUnpacking: this.tabType === 'bad',
        curPage: this.curPage,
        pageSize: this.pageSize,
        qualityStatus: this.qualityStatus,
        orderStatus: this.orderStatus,
        code: this.code,
        storeSettlementType: this.storeSettlementType,
        storeName: this.storeName,
        orderCode: this.orderCode,
        categoryId: this.categoryId,
        serialNum: this.serialNum,
        repairNum: this.repairNum,
        configFaultId: this.configFaultId,
        failCat: this.failCat,
        ownershipResponsibility: this.ownershipResponsibility,
        causeAnalysis: this.causeAnalysis,
        startTime: this.startTime,
        endTime: this.endTime,
        orderType: this.orderType,
        orderTypeChi: this.orderTypeChi
      }
      this.loading = true
      qcCheckList(params).then(res => {
        this.loading = false
        const { list, totalCount } = res.data
        this.tableData = Object.freeze(list)
        this.total = Object.freeze(totalCount)
      })
    },
    codeBlur (val) {
      this.code = val
    },
    storeSettlementTypeChange (val) {
      this.storeSettlementType = val
    },
    orderCodeBlur (val) {
      this.orderCode = val
    },
    serialNumBlur (val) {
      this.serialNum = val
    },
    statusChange (val) {
      this.qualityStatus = val
    },
    orderStatusChange (val) {
      this.orderStatus = val
    },
    repairNumChange (val) {
      this.repairNum = val
    },
    categoryIdChange (val) {
      if (val) {
        this.categoryId = val.resultId
        this.getDeviceFaultList(val.resultId)
      } else {
        this.categoryId = ''
        this.deviceFaultList = []
      }
    },
    storeNameBlur (val) {
      this.storeName = val
    },
    configFaultChange (val) {
      this.configFaultId = val
    },
    failCatChange (val) {
      this.failCat = val
    },
    causeAnalysisChange (val) {
      this.causeAnalysis = val
    },
    ownershipResponsibilityChange (val) {
      this.ownershipResponsibility = val
    },
    dateChange (startTime, endTime) {
      this.startTime = startTime
      this.endTime = endTime
    },
    orderTypeChange (val) {
      this.orderType = val
      this.orderTypeChi = ''
      if (val) {
        this.orderTypeChiList = this['orderTypeChiList' + val]
      } else {
        this.orderTypeChiList = []
      }
    },
    orderTypeChiChange (val) {
      this.orderTypeChi = val
    },
    // 复核
    audit (info) {
      const { orderId, categoryId, orderCode } = info
      this.SET_DRAWER_SHOW(true)
      this.SET_INDEX_INFO({ id: orderId, categoryId, orderCode })
    },
    // 批量复查
    batchHandleRepeatCheck () {
      this.hintShow = true
    },
    hintSure () {
      const idList = this.selectData.map(item => ({
        id: item.id,
        orderId: item.orderId
      }))
      qcCheckBatchCheck({ equipmentItems: idList }).then(res => {
        // 不管是否成功都关闭弹窗刷新页面
        this.$message.success('复查成功')
        this.close()
      }).catch(_ => {
        this.close()
      })
    },
    batchHandle () {
      this.batchHandleShow = true
    },
    close () {
      this.selectIds = []
      this.batchHandleShow = false
      this.hintShow = false
      this.SET_DRAWER_SHOW(false)
      this.getList()
    },
    search () {
      this.curPage = 1
      this.getList()
    },
    reset () {
      this.curPage = 1
      this.orderStatus = ''
      this.qualityStatus = 0
      this.code = ''
      this.storeSettlementType = ''
      this.storeName = ''
      this.orderCode = ''
      this.categoryId = ''
      this.serialNum = ''
      this.repairNum = ''
      this.configFaultId = ''
      this.startTime = ''
      this.endTime = ''
      this.date = []
      this.deviceFaultList = []
      this.causeAnalysisList = []
      this.failCatList = []
      this.ownershipResponsibilityList = []
      this.deviceFaultArr = []
      this.failCatArr = []
      this.ownershipResponsibilityArr = []
      this.causeAnalysisArr = []
      this.configFaultId = ''
      this.failCat = ''
      this.ownershipResponsibility = ''
      this.causeAnalysis = ''
      this.orderTypeChiList = []
      this.orderType = ''
      this.orderTypeChi = ''
      this.getSelectData(101015, 'failCatList')
      this.getSelectData(101018, 'causeAnalysisList')
      this.getSelectData(101024, 'ownershipResponsibilityList')
      this.getList()
    },
    select (val) {
      this.selectIds = val.map(item => item.id)
      this.selectData = val
    },
    selectAll (val) {
      this.selectIds = val.map(item => item.id)
      this.selectData = val
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
