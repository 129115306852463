<template>
  <div class="layout_common">
    <div class="main_box">
      <div class="main_box_con" style="padding-top:10px">
        <el-tabs class="tabs" v-model="activeName" style="padding-left:0">
          <el-tab-pane label="开箱不良" name="bad">
            <comp :tabType="activeName" v-if="activeName==='bad'" />
          </el-tab-pane>
          <el-tab-pane label="开箱正常" name="good">
            <comp :tabType="activeName" v-if="activeName==='good'" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import comp from './comp'
export default {
  components: {
    comp
  },
  data () {
    return {
      activeName: 'bad'
    }
  }
}
</script>
