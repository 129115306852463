<template>
  <dialogComp :title="dialogTitle" :isShow="isShow" @close="close" @sure="sure">
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="100px"
      style="text-align: center"
    >
      <el-form-item label="责任归属:" prop="ownershipResponsibility">
        <cascader
          :data="ownershipResponsibilityList"
          :hval="ownershipResponsibilityArr"
          @change="ownershipResponsibilityChange"
          placeholder="请选择责任归属"
        />
      </el-form-item>
      <el-form-item
        label="开箱不良:"
        prop="causeAnalysis"
        class="poorUnpacking"
      >
        <cascader
          :data="causeAnalysisList"
          :hval="causeAnalysisArr"
          @change="causeAnalysisChange"
          placeholder="请选择开箱不良原因"
        />
      </el-form-item>
    </el-form>
  </dialogComp>
</template>

<script>
import { dictThree, qcCheckBatchUpdate } from '@/api'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    selectIds: {
      type: Array,
      default: () => []
    },
    tabType: {
      type: String,
      default: 'bad'
    }
  },
  data () {
    return {
      causeAnalysisList: [],
      failCatList: [],
      treatmentMethodList: [],
      ownershipResponsibilityList: [],
      deviceFaultList: [],
      deviceFaultArr: [],
      failCatArr: [],
      treatmentMethodArr: [],
      ownershipResponsibilityArr: [],
      causeAnalysisArr: [],
      formData: {
        causeAnalysis: '',
        causeAnalysisOne: '',
        causeAnalysisTwo: '',
        causeAnalysisThree: '',
        ownershipResponsibility: '',
        ownershipResponsibilityOne: '',
        ownershipResponsibilityTwo: '',
        ownershipResponsibilityThree: ''
      },
      rules: {}
    }
  },
  watch: {
    isShow (val) {
      if (val) {
        this.getSelectData(101018, 'causeAnalysisList')
        this.getSelectData(101024, 'ownershipResponsibilityList')
      }
    }
  },
  computed: {
    dialogTitle () {
      return this.tabType === 'bad' ? '批量取消开箱不良' : '批量改为开箱不良'
    }
  },
  methods: {
    // 获取展开后  多个下拉列表 问题归类\原因分析\处理方式
    async getSelectData (code, data) {
      const params = {
        code
      }
      const res = await dictThree(params)
      if (res.code === '1') {
        this[data] = res.data
      }
    },
    // 封装方法鉴定记录选择事件
    handleChangeFn (val, type, one, two, three) {
      switch (val.length) {
        case 0:
          this.formData[type] = ''
          this.formData[one] = ''
          this.formData[two] = ''
          this.formData[three] = ''
          break
        case 1:
          this.formData[type] = val[0]
          this.formData[one] = ''
          this.formData[two] = ''
          this.formData[three] = ''
          break
        case 2:
          this.formData[type] = val[0]
          this.formData[one] = val[1]
          this.formData[two] = ''
          this.formData[three] = ''
          break
        case 3:
          this.formData[type] = val[0]
          this.formData[one] = val[1]
          this.formData[two] = val[2]
          this.formData[three] = ''
          break
        case 4:
          this.formData[type] = val[0]
          this.formData[one] = val[1]
          this.formData[two] = val[2]
          this.formData[three] = val[3]
          break
      }
    },
    causeAnalysisChange (val) {
      this.handleChangeFn(val, 'causeAnalysis', 'causeAnalysisOne', 'causeAnalysisTwo', 'causeAnalysisThree')
    },
    ownershipResponsibilityChange (val) {
      this.handleChangeFn(val, 'ownershipResponsibility', 'ownershipResponsibilityOne', 'ownershipResponsibilityTwo', 'ownershipResponsibilityThree')
    },
    close () {
      this.$refs.formData.resetFields()
      this.ownershipResponsibilityArr = []
      this.causeAnalysisArr = []
      this.formData = {
        causeAnalysis: '',
        causeAnalysisOne: '',
        causeAnalysisTwo: '',
        causeAnalysisThree: '',
        ownershipResponsibility: '',
        ownershipResponsibilityOne: '',
        ownershipResponsibilityTwo: '',
        ownershipResponsibilityThree: ''
      }
      this.$emit('close')
    },
    sure () {
      const params = {
        ids: this.selectIds,
        // 取消开箱不良传false 改为开箱不良传true
        poorUnpacking: this.tabType !== 'bad',
        ...this.formData
      }
      qcCheckBatchUpdate(params).then((res) => {
        this.$message.success('批量处理成功')
        this.close()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .poorUnpacking {
  .el-form-item__content {
    display: flex;
  }
}
</style>
